<template>
  <div class="wx_login">
    <p>本服务由聚客财经提供</p>
    <el-button type="primary" @click="_wxLogin">确认登录</el-button>
  </div>
</template>

<script>
import { wxLogin } from "@/api/wxchat";
import { setToken } from "@/utils/auth";
import { ElMessage } from "element-plus";

export default {
  methods: {
    _wxLogin() {
      wxLogin({
        code: this.$route.query.code,
      }).then((result) => {
        console.log(result)
        ElMessage.success("授权成功");
      });
    },
  },
  mounted() {
    setToken(this.$route.query.token);
  },
};
</script>

<style lang="scss" scoped>
.wx_login {
  margin-bottom: 20px;
}
</style>
